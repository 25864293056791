.avatar_wrapper {
  width: 100px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid;
  height: 100px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.no-avatar {
  width: 2em;
  height: 2em;
}

.avatar {
  height: 100px;
  max-width: fit-content;
  margin: 0 auto;
}
